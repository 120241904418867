.menu-button {
  width: 100%;
  padding: 9px 12px 9px 30px;
  text-align: left;
  background-color: transparent;
  border: none;
}

.menu-button > .icon-globe {
  font-size: 16px;
  color: #a7bdcd; /* light.min.css */
}

.menu-button:hover > .icon-globe {
  color: #5b9bd1; /* light.min.css */
}

.menu-item {
  width: 96%;
  padding: 9px 12px 9px 30px;
  display: block;
  margin-left: 25px;
  color: #34495e; /* components.min.css */
}

@media (min-width: 992px) {
  .menu-item {
    width: 91%;
    padding: 9px 6px 9px 30px;
  }
}

.menu-item:hover {
  text-decoration: none;
}

.menu-button:hover,
.menu-item:hover,
.menu-item-active {
  color: #5b9bd1 !important; /* light.min.css */
  background-color: #f2f6f9 !important; /* light.min.css */
}
